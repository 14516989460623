<script>
import WTextAbstract from '@/components/Commons/WTextAbstract'

/**
 * @displayName w-text-info
 */
export default {
	name: 'WTextInfo',
	extends: WTextAbstract,
	data: function () {
		return {
			color: 'info',
			icon: 'info'
		}
	}
}
</script>
